let config = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "forwood-riotinto-testing-forwoodid",
    BATCH_PROCESS_BUCKET_NAME: "forwood-riotinto-testing-forwoodid-batch-user-process",
  },
  appSyncAPI: {
    REGION: "us-east-1",
    URL: "https://wuyati4udjbqvdmj2p23s5aoza.appsync-api.us-east-1.amazonaws.com/graphql",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://kzz38xol82.execute-api.us-east-1.amazonaws.com/env",
    USER_TOKEN_URL: "https://wzmc3sfhh4.execute-api.us-east-1.amazonaws.com/prod",
  },
  configurationService: {
    URL: "https://config.testing.riotinto.forwoodsafety.com",
    WEBSOCKET: "wss://0y3pdsom96.execute-api.us-east-1.amazonaws.com/testing",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_mTKeit2kD",
    IDENTITY_POOL_ID: "us-east-1:5c33bdbd-dc78-4d70-9214-8a0ee601eda5",
    USERPOOL_HOSTED_DOMAIN: "forwood-riotinto-id-testing",
    USERPOOL_ADFS_CALLBACK_URL: "https://id.testing.riotinto.forwoodsafety.com",
    USERPOOL_ADFS_LOGOUT_URL: "https://id.testing.riotinto.forwoodsafety.com",
    AUTHENTICATED_ROLE_ARN: "arn:aws:iam::391675274246:role/testing-CA",
  },
  samlProvider: {
    NAME: "ForwoodAzure",
    SUPPORTED_LIST: "ForwoodAzure",
  },
  reactApp: {
    VERSION: "1.37.0",
    HOSTNAME: "id.testing.riotinto.forwoodsafety.com",
    ENV_NAME: "testing",
    COOKIE_DOMAIN: ".testing.riotinto.forwoodsafety.com",
    SECURE_PROTOCOL: true,
    FORWOOD_COMPANY_ID: "ad693bbf-7a7f-4a1c-a426-675a6eaffea7",
    FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.testing.riotinto.forwoodsafety.com",
  },
  USERPOOL_ADFS_LAUNCH_URL(clientId) {
    return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
  },
};

export default config;
